<template>
  <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5"><strong>HEMODILUTION ASSESSMENT</strong></span>
          </v-card-title>
          <v-row class="mx-3 pt-3 justify-space-between">
            <v-col cols="12" md="4">
              <v-text-field v-model="Listdata.donor_name" label="Donor Name" hide-details readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="Listdata.donor_number" label="Phone Number" hide-details readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 justify-space-between">
            <v-col cols="12" md="4">
              <v-datetime-picker 
                v-model="sampleDraenDataTine" 
                date-format="dd-MM-yyyy" 
                time-format="HH:mm" 
                label="Sample Drawn Date and Time*"
                :text-field-props="textFieldProps" :readonly="readOnlyField1 === true" :error="errr === false">
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" md="4">
              <!-- <v-text-field v-model="Listdata.sample_drawn_by" label="Sample Drawn By*" hide-details :readonly="readOnlyField1 === true" :error="errr1 === false"></v-text-field> -->
              <v-text-field v-model="Listdata.sample_drawn_by" label="Sample Drawn By*" hide-details readonly :error="errr1 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 pt-3 mb-3">
            <v-col cols="12" md="6">
            <p>Start(was donor infused or transfused prior to sample collection)*</p>
              <!-- <v-autocomplete label="Start(was donor infused or transfused prior to sample collection)"
                v-model="Listdata.was_donor_prior_infused" :items="['Yes', 'No']" hide-details></v-autocomplete> -->
                <v-radio-group row hide-details class="mt-0" 
                v-model="Listdata.was_donor_prior_infused" @change="restbutton(Listdata.was_donor_prior_infused)" :readonly="readOnlyField1 === true" :error="errr2 === false">
                <v-radio name="name" label="Yes" value="Yes"></v-radio>
                <v-radio  name="name"  label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="5" v-if="Listdata.was_donor_prior_infused == 'No'">

              <v-file-input v-model="testSampleInitial" chips accept="image/*" label="Test Sample Initial"
                @change="onFileChange" :readonly="readOnlyField1 === true" :error="errr4 === false"/>
              <v-col v-if="!testSampleInitialObj && Listdata.test_sample_initial" cols="6">
                <img  v-img style="width:100%" :src="!testSampleInitialObj?`https://api.ebsr.in/${Listdata.test_sample_initial}`:''" />
              </v-col>
              <v-col v-if="!!testSampleInitialObj" cols="6">
                <img v-img style="width: 100%;" :src="testSampleInitialObj?testSampleInitialObj:''" />
              </v-col>
              <!-- <v-col cols="12" md="4">
                <img v-img style="width:100%" :src="`https://api.ebsr.in/${Listdata.test_sample_initial}`" />
              </v-col> -->
            </v-col>
            <v-col cols="12" md="3" v-if="Listdata.was_donor_prior_infused == 'No'">
              <v-datetime-picker v-model="testSampleDate" date-format="dd-MM-yyyy" time-format="HH:mm" label="Test Sample Date*"
                :text-field-props="textFieldProps" :readonly="readOnlyField1 === true" :error="errr5 === false"></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row class="mx-3 pt-3 mb-3">
            <v-col cols="12" md="4" v-if="Listdata.was_donor_prior_infused == 'Yes'">
              <v-autocomplete v-model="Listdata.is_donar_greater_than_12_years" @change="restfield(Listdata.is_donar_greater_than_12_years)" hide-details :items="['Yes', 'No']"
                label="Is the Donor >12 years old ?*" :readonly="readOnlyField1 === true" :error="errr6 === false"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4"
              v-if="Listdata.is_donar_greater_than_12_years == 'Yes' && Listdata.was_donor_prior_infused == 'Yes'">
              <v-autocomplete v-model="Listdata.has_extravascular_blood_loss_occurred" hide-details @change="restbutton2(Listdata.has_extravascular_blood_loss_occurred)"
                :items="['Yes', 'No']" label="Has extravascular blood loss occurred (internal or external bleeding)?*" :readonly="readOnlyField1 === true" :error="errr7 === false">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="5"
              v-if="Listdata.has_extravascular_blood_loss_occurred == 'No' && Listdata.is_donar_greater_than_12_years == 'Yes'">
              <v-file-input v-model="testSampleInitial" chips accept="image/*" label="Test Sample Initial*"
                @change="onFileChange" :readonly="readOnlyField1 === true" :error="errr4 === false"/>
              <v-col v-if="!testSampleInitialObj && Listdata.test_sample_initial" cols="6">
                <img  v-img style="width:100%" :src="!testSampleInitialObj?`https://api.ebsr.in/${Listdata.test_sample_initial}`:''" />
              </v-col>
             <v-col v-if="!!testSampleInitialObj"  cols="6">
            <img v-img style="width: 100%;" :src="testSampleInitialObj?testSampleInitialObj:''" />
            </v-col>
              <!-- <v-col cols="12" md="4">
                <img v-img style="width:100%" :src="`https://api.ebsr.in/${Listdata.test_sample_initial}`" />
              </v-col> -->

            </v-col>
            <v-col cols="12" md="4"
              v-if="Listdata.has_extravascular_blood_loss_occurred == 'No' && Listdata.is_donar_greater_than_12_years == 'Yes'">
              <v-datetime-picker v-model="testSampleDate" date-format="dd-MM-yyyy" time-format="HH:mm" label="Test Sample Date*"
                :text-field-props="textFieldProps" :readonly="readOnlyField1 === true" :error="errr5 === false"></v-datetime-picker>
            </v-col>
          </v-row>
          <template v-if="Listdata.is_donar_greater_than_12_years == 'No' || (Listdata.has_extravascular_blood_loss_occurred == 'Yes' && Listdata.was_donor_prior_infused == 'Yes')">
          <v-row class="mx-3 pt-3 mb-3">
            <v-col cols="12" md="12">
              <v-card-subtitle class="mb-0 pb-0 text-center">
                <span>Record volume of blood products transfused during the 48 hours before the sample was<br> drawn (or
                  cardiac death if drawn post cardiac death)</span>
              </v-card-subtitle>
            </v-col>
            <!-- Blood Product -->
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Blood Product</p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Date and Time</p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Volume</p>
            </v-col>
            <template v-for="bloodObj in blood_product_arr_com">
              <v-row class="mx-3">
                <v-col cols="12" md="4">
                  <v-text-field v-model="bloodObj.blood_product" hide-details :readonly="readOnlyField1 === true"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-datetime-picker v-model="bloodObj.date_time" time-format="HH:mm"
                    :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="bloodObj.blood_volume" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true"></v-text-field>
                </v-col>
              </v-row>
            </template>
            <!-- End Blood Product -->
            <v-col cols="12" md="8">
              <p class="mt-6 pl-2">Total Blood products Transfused in 48 hours (A)</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model='Listdata.total_blood_products_transfused_in_48_hours' readonly hide-details
                @keypress="onlyNumber" >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="mt-8">
              <v-card-subtitle class="mb-0 pb-0 text-center">
                <span>Record volume of Colloids transfused during the 48 hours before the sample was drawn<br> (or
                  cardiac
                  death if drawn post cardiac death)</span>
              </v-card-subtitle>
            </v-col>

            <!-- Colloid  -->
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Colloid</p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Date and Time</p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Volume</p>
            </v-col>
            <template v-for="colloidObj in colloid_arr_com">
              <v-row class="mx-3">
                <v-col cols="12" md="4">
                  <v-text-field v-model="colloidObj.colloid" hide-details :readonly="readOnlyField1 === true"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-datetime-picker v-model="colloidObj.date_time" time-format="HH:mm"
                    :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="colloidObj.colloid_volume" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true"></v-text-field>
                </v-col>
              </v-row>
            </template>
            <!-- End Collid Product -->
            <v-col cols="12" md="8">
              <p class="mt-6 pl-2">Total Colloids Transfused in 48 hours (B)</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model='Listdata.total_colloids_transfused_in_48_hours' readonly @keypress="onlyNumber"
                hide-details>
              </v-text-field>
            </v-col>


            <!-- Crystalloid  -->
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Crystalloid</p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Date and Time</p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="body-2 mt-2 mb-0 text-center">Volume</p>
            </v-col>
            <template v-for="crystalloidObj in crystalloid_arr_com">
              <v-row class="mx-3">
                <v-col cols="12" md="4">
                  <v-text-field v-model="crystalloidObj.crystalloid" hide-details :readonly="readOnlyField1 === true"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-datetime-picker v-model="crystalloidObj.date_time" time-format="HH:mm"
                    :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="crystalloidObj.crystalloid_volume" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true">
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <!-- End Crystalloid -->
            <v-col cols="12" md="8">
              <p class="mt-6 pl-2">Total Crystlloid infused in 1 hour (C)</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model='Listdata.total_crystalloid_infused_in_1_hour' readonly @keypress="onlyNumber"
                hide-details>
              </v-text-field>
            </v-col>
          </v-row>

          <v-col class="mb-0 pb-0 mt-6" cols="12" md="12"
            v-if="Listdata.is_donar_greater_than_12_years == 'No' || (Listdata.has_extravascular_blood_loss_occurred == 'Yes' && Listdata.was_donor_prior_infused == 'Yes')">
            <v-card-subtitle class="mb-0 body-1">
              <span>Calculate Donor plasma Volume(PV) & Donor Blood volume(BV)[lbs+2.2=kg]</span>
            </v-card-subtitle>
          </v-col>
          <v-row class="mx-6 pt-0 mb-0 pb-0"
            v-if="Listdata.is_donar_greater_than_12_years == 'No' || (Listdata.has_extravascular_blood_loss_occurred == 'Yes' && Listdata.was_donor_prior_infused == 'Yes')">
            <v-col cols="12" md="2" class="mb-0 pb-0">
              <p class="body-2 mt-5 mb-0">PV = Donor wt(Kg)</p>
            </v-col>
            <v-col cols="12" md="2" class="mb-0 pb-0">
              <v-text-field v-model="Listdata.donor_weight" @keypress="onlyNumber" @change="plasma_Volume()" :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mb-0 pb-0">
              <p class="body-2 mt-5 mb-0">/ 0.025 = </p>
            </v-col>
            <!-- {{ Listdata.plasma_volume / 0.025 }} -->
            <v-col cols="12" md="2" class="mb-0 pb-0">
              <!-- <p class="body-2 mt-5 mb-0">/ 0.025 = {{ Listdata.plasma_volume / 0.025 }} ml</p> -->
              <v-text-field v-model="plasmaVolume" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mb-0 pb-0">
              <p class="body-2 mt-5 mb-0">ml </p>
            </v-col>
          </v-row>

          <v-row class="mx-6 pt-0 mt-0 pb-0"
            v-if="Listdata.is_donar_greater_than_12_years == 'No' || (Listdata.has_extravascular_blood_loss_occurred == 'Yes' && Listdata.was_donor_prior_infused == 'Yes')">
            <v-col cols="12" md="2" class="mt-0 pt-0 mb-0 pb-0">
              <p class="body-2 mt-3 mb-0">BV = Donor wt(Kg)</p>
            </v-col>
            <v-col cols="12" md="2" class="mt-0 pt-0 mb-0 pb-0">
              <v-text-field class="pt-0" v-model="Listdata.donor_weight" @keypress="onlyNumber"
                @change="plasma_Volume()" :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mt-0 pt-0 mb-0 pb-0">
              <p class="body-2 mt-3 mb-0">/ 0.015 = </p>
            </v-col>
            <v-col cols="12" md="2" class="mt-0 pt-0 mb-0 pb-0">
              <!-- <p class="body-2 mt-5 mb-0">/ 0.015 = {{ (Listdata.blood_volume / 0.015).toFixed(2) }} ml</p> -->
              <v-text-field class="pt-0" v-model="bloodVolume" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="mt-0 pt-0 mb-0 pb-0">
              <p class="body-2 mt-3 mb-0">ml </p>
            </v-col>
          </v-row>

          <v-row class="mx-6 pt-0"
            v-if="Listdata.is_donar_greater_than_12_years == 'No' || (Listdata.has_extravascular_blood_loss_occurred == 'Yes' && Listdata.was_donor_prior_infused == 'Yes')">
            <v-col cols="12" md="2">
              <p class="body-2 mt-5 mb-0">
                B+C = {{
                    (
                      Listdata.total_colloids_transfused_in_48_hours ?
                        parseInt(Listdata.total_colloids_transfused_in_48_hours)
                        : 0) +
                    (Listdata.total_crystalloid_infused_in_1_hour ?
                      parseInt(Listdata.total_crystalloid_infused_in_1_hour) : 0)
                }}
              </p>
            </v-col>
            <v-col cols="12" md="2">
              <p class="body-2 mt-5 mb-0">Is B+C > PV ?</p>
            </v-col>
          </v-row>

          <v-row class="mx-6 pt-3"
            v-if="parseInt(Listdata.total_colloids_transfused_in_48_hours) + parseInt(Listdata.total_crystalloid_infused_in_1_hour) > Listdata.donor_weight / 0.025">
            <v-col cols="12" md="5">

              <v-file-input v-model="rejectSampleInitial" chips accept="image/*" label="Reject Sample Initial"
                @change="onFileChangeReject" :readonly="readOnlyField1 === true" />


        <v-col v-if="!rejectSampleInitialObj && Listdata.reject_sample_initial" cols="5">
          <img  v-img style="width:100%" :src="!rejectSampleInitialObj?`https://api.ebsr.in/${Listdata.reject_sample_initial}`:''" />
          
       </v-col>
        <v-col v-if="!!rejectSampleInitialObj"  cols="5">
            <img v-img style="width: 100%;" :src="rejectSampleInitialObj?rejectSampleInitialObj:''" />
        </v-col>
              <!-- <v-col cols="12" md="4">
                <img v-img style="width:100%" :src="`https://api.ebsr.in/${Listdata.reject_sample_initial}`" />
              </v-col> -->
            </v-col>
            <v-col cols="12" md="4">
              <v-datetime-picker v-model="rejectSampleDate" date-format="dd-MM-yyyy" time-format="HH:mm" label="Reject Sample Date"
                :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row class="mx-6 pt-0"
            v-else-if="parseInt(Listdata.total_colloids_transfused_in_48_hours) + parseInt(Listdata.total_crystalloid_infused_in_1_hour) < Listdata.donor_weight / 0.025">
            <v-col cols="12" md="2">
              <p class="body-2 mt-5 mb-0">
                A+B+C =
                {{
                    parseInt(Listdata.total_blood_products_transfused_in_48_hours) +
                    parseInt(Listdata.total_colloids_transfused_in_48_hours) +
                    parseInt(Listdata.total_crystalloid_infused_in_1_hour)
                }}
              </p>
            </v-col>
            <v-col cols="12" md="2">
              <p class="body-2 mt-5 mb-0">Is A+B+C > BV ?</p>
            </v-col>
            <v-row class="mx-3 pt-3" v-if="
            parseInt(Listdata.total_blood_products_transfused_in_48_hours) +
            parseInt(Listdata.total_colloids_transfused_in_48_hours) +
            parseInt(Listdata.total_crystalloid_infused_in_1_hour) >
            (Listdata.donor_weight / 0.015).toFixed(2)">
              <v-col cols="12" md="5">

                <v-file-input v-model="rejectSampleInitial" chips accept="image/*" label="Reject Sample Initial"
                  @change="onFileChangeReject" :readonly="readOnlyField1 === true" />
                <!-- <v-col cols="12" md="4">
                  <img v-img style="width:100%"
                    :src="`https://api.ebsr.in/${Listdata.reject_sample_initial}`" />
                </v-col> -->
                 <v-col v-if="!rejectSampleInitialObj && Listdata.reject_sample_initial" cols="4">
          <img  v-img style="width:100%" :src="!rejectSampleInitialObj?`https://api.ebsr.in/${Listdata.reject_sample_initial}`:''" />
       </v-col>
        <v-col v-if="!!rejectSampleInitialObj"  cols="4">
            <img v-img style="width: 100%;" :src="rejectSampleInitialObj?rejectSampleInitialObj:''" />
        </v-col>
              </v-col>
              <v-col cols="12" md="4">
                <v-datetime-picker v-model="rejectSampleDate" date-format="dd-MM-yyyy" time-format="HH:mm" label="Reject Sample Date"
                  :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker>
              </v-col>
            </v-row>
            <v-row class="mx-3 pt-3" v-else-if="
            parseInt(Listdata.total_blood_products_transfused_in_48_hours) +
            parseInt(Listdata.total_colloids_transfused_in_48_hours) +
            parseInt(Listdata.total_crystalloid_infused_in_1_hour) <
            (Listdata.donor_weight / 0.015).toFixed(2)">
              <v-col cols="12" md="5">

                <v-file-input v-model="testSampleInitial" chips accept="image/*" label="Test Sample Initial*"
                  @change="onFileChange" :readonly="readOnlyField1 === true" :error="errr4 === false"/>
                     <v-col v-if="!testSampleInitialObj && Listdata.test_sample_initial" cols="6">
                <img  v-img style="width:100%" :src="!testSampleInitialObj?`https://api.ebsr.in/${Listdata.test_sample_initial}`:''" />
              </v-col>
             <v-col v-if="!!testSampleInitialObj"  cols="6">
            <img v-img style="width: 100%;" :src="testSampleInitialObj?testSampleInitialObj:''" />
            </v-col>
                <!-- <v-col cols="12" md="4">
                  <img v-img style="width:100%" :src="`https://api.ebsr.in/${Listdata.test_sample_initial}`" />
                </v-col> -->
              </v-col>
              <v-col cols="12" md="4">
                <v-datetime-picker v-model="testSampleDate" date-format="dd-MM-yyyy" time-format="HH:mm" label="Test Sample Date*"
                  :text-field-props="textFieldProps" :readonly="readOnlyField1 === true" :error="errr5 === false"></v-datetime-picker>
              </v-col>
            </v-row>
          </v-row>
          </template>

          <v-row class="mx-6 pt-3">
            <v-col cols="12" class="mb-0">
              <p class="text-h6 mb-0">Final Review</p>
            </v-col>
          </v-row>
          <v-row class="mx-6 pt-0">
            <v-col cols="12" md="7" class="pt-0">
              <p class="mb-0">Hemodilution has been reviewed and determined acceptable</p>
            </v-col>
            <v-col cols="5" md="5" class="pt-0">
              <v-file-input v-model="Signature" chips accept="image/*" label="Signature*" @change="onFileclick" :readonly="readOnlyField1 === true" />
              <v-datetime-picker v-model="finalReviewDate" date-format="dd-MM-yyyy" time-format="HH:mm" label="Date and Time*" :readonly="readOnlyField1 === true" :error="errr8 === false">
              </v-datetime-picker>

               <v-col v-if="!SignatureObj && Listdata.final_review_signature" cols="6">
                <img  v-img style="width:100%" :src="!SignatureObj?`https://api.ebsr.in/${Listdata.final_review_signature}`:''" />
              </v-col>
              <v-col v-if="!!SignatureObj"  cols="6">
                <img v-img style="width: 100%;" :src="SignatureObj?SignatureObj:''" />
              </v-col>
              <!-- <v-col cols="12" md="4">
                <img v-img style="width:100%" :src="`https://api.ebsr.in/${Listdata.final_review_signature}`" />
              </v-col> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <!-- <v-btn class="float-right mr-6 mb-3" color="primary" text @click="e1 = 2"> Next </v-btn> -->
              <v-btn class="float-right mr-3 mb-3" color="primary" v-if="saveBtn" @click="same()"> Save </v-btn>
            </v-col>
          </v-row>
       
 
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import Hemodilutionservice from '@/service/Hemodilution.service'
import { api } from '@/config/config'
import moment from 'moment'

const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const userId = localData.user_id
export default {
  data() {
    return {
      errr:true,
      errr1:true,
      errr2:true,
      errr4:true,
      errr5:true,
      errr6:true,
      errr7:true,
      errr8:true,
     
      formtype:'',
      rejectSampleInitialObj:'',
      testSampleInitialObj:'',
      e1: '1',
      SignatureObj: "",
      // donor_weight:'',
      Listdata: { total_blood_products_transfused_in_48_hours: 0 },

      blood_product_arr: [
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
      ],
      colloid_arr: [
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' }
      ],
      crystalloid_arr: [
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
      ],
      wbc_performed_arr: [
        { date_time: '', count: '' },
        { date_time: '', count: '' },
        { date_time: '', count: '' },
      ],
      temperature_arr: [
        { date_time: '', result: '', units: '' },
        { date_time: '', result: '', units: '' },
        { date_time: '', result: '', units: '' },
      ],
      cultures_source_arr: [
        { cultures_source: '', date_time: '', result: '' },
        { cultures_source: '', date_time: '', result: '' },
        { cultures_source: '', date_time: '', result: '' },

      ],

      
      rejectSampleInitial: null,
      testSampleInitial: null,

      plasmaVolume: '',
      bloodVolume: '',
      total_blood_products_transfused_in_48_hours: null,
      total_colloids_transfused_in_48_hours: null,
      image: null,
      Signature: null,
      total_crystalloid_infused_in_1_hour: null,
      was_donor_prior_infused: '',
      Clear: '',
      Clears: '',
      snackbar: false,
      snackbarText: '',
      listLoading: false,
      saveBtn:true,
      readOnlyField1:false,
      timeout: 2000,
      is_donar_greater_than_12_years: '',
      has_extravascular_blood_loss_occurred: '',
      textFieldProps: {
        // outlined: true,
        // dense: true,
        'hide-details': true,
      },
    }
  },

  computed: {

    sampleDraenDataTine: {
      get() {
        return this.Listdata.sample_drawn_date_time ? moment.utc(this.Listdata.sample_drawn_date_time).format('DD-MM-YYYY HH:mm') : null
      },
      set(value) {
        this.$set(this.Listdata, 'sample_drawn_date_time', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

    testSampleDate: {
      get() {
        return moment.utc(this.Listdata.test_sample_date).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.Listdata, 'test_sample_date', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },



    blood_product_arr_com: function () {
      return this.blood_product_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },

    colloid_arr_com: function () {
      return this.colloid_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },

    crystalloid_arr_com: function () {
      return this.crystalloid_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },



    temperature_arr_com: function () {
      return this.temperature_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },

    wbc_performed_arr_com: function () {
      return this.wbc_performed_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },

    cultures_source_arr_com: function () {
      return this.cultures_source_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },




    bloodProductDateAndTime: {
      get() {
        return this.Listdata.blood_product_date_and_time ? moment(this.Listdata.blood_product_date_and_time).format('DD-MM-YYYY HH:mm') : ''
      },
      set(value) {
        this.$set(this.Listdata, 'blood_product_date_and_time', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm'))
      },
    },

    colloidDateAndTime: {
      get() {
        return this.Listdata.colloid_date_and_time ? moment(this.Listdata.colloid_date_and_time).format('DD-MM-YYYY HH:mm') : ''
      },
      set(value) {
        this.$set(this.Listdata, 'colloid_date_and_time', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm'))
      },
    },

    crystalloidDateAndTime: {
      get() {
        return this.Listdata.crystalloid_date_and_time ? moment(this.Listdata.crystalloid_date_and_time).format('DD-MM-YYYY  HH:mm') : ''
      },
      set(value) {
        this.$set(this.Listdata, 'crystalloid_date_and_time', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm'))
      },
    },

    rejectSampleDate: {
      get() {
        return moment.utc(this.Listdata.reject_sample_date).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.Listdata, 'reject_sample_date', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

    finalReviewDate: {
      get() {
        return this.Listdata.final_review_date ? moment.utc(this.Listdata.final_review_date).format('DD-MM-YYYY HH:mm') : ''
      },
      set(value) {
        this.$set(this.Listdata, 'final_review_date', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

    WBc_performed: function () {

      return this.Wbc_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).utcOffset("+05:30").format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },

    cultures_done: function () {

      return this.cultures_done_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).utcOffset("+05:30").format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },

    temperature_recorded: function () {

      return this.temperature_recorded_arr.map((val) => {
        val.date_time = val.date_time ? moment(val.date_time).utcOffset("+05:30").format('YYYY-MM-DD HH:mm') : ''
        return val
      })
    },
  },
  watch: {
    options: {
      handler() {
        this.HemodilutionData()
        this.getdonorList()

      },
      deep: true,
    },
    blood_product_arr_com: {
      handler(newValue, oldValue) {
        this.Listdata.total_blood_products_transfused_in_48_hours = this.blood_product_arr_com.reduce((acc, val) => { return acc + (val.blood_volume ? Number(val.blood_volume) : 0) }, 0)
      },
      deep: true
    },
    colloid_arr_com: {
      handler(newValue, oldValue) {
        this.Listdata.total_colloids_transfused_in_48_hours = this.colloid_arr_com.reduce((acc, val) => { return acc + (val.colloid_volume ? Number(val.colloid_volume) : 0) }, 0)
      },
      deep: true
    },
    crystalloid_arr_com: {
      handler(newValue, oldValue) {
        // console.log(this.crystalloid_arr_com.reduce((acc, val) => { console.log(val, val.crystalloid_volume) }, 0))
        this.Listdata.total_crystalloid_infused_in_1_hour = this.crystalloid_arr_com.reduce((acc, val) => { return acc + (val.crystalloid_volume ? Number(val.crystalloid_volume) : 0) }, 0)
      },
      deep: true
    },

  },

  async mounted() {
    this.HemodilutionData()
    this.getdonorList()
  },

  methods: {
    same(){
       if(this.Listdata.sample_drawn_date_time == "" || this.Listdata.sample_drawn_date_time === null || this.Listdata.sample_drawn_date_time == "Invalid date"){
        this.errr = false
        return false,
        this.snackbarText = "Please Fill the sample drawn date time",
        this.snackbar = true
      }
      if(this.Listdata.sample_drawn_by == "" || this.Listdata.sample_drawn_by === null){
        this.errr1 = false
        return false,
        this.snackbarText = "Please Fill the sample drawn By",
        this.snackbar = true
      }
      if(this.Listdata.was_donor_prior_infused == "" || this.Listdata.was_donor_prior_infused === null){
        this.errr2 = false
         return false,
        this.snackbarText = "Please Fill the Start",
        this.snackbar = true
      }
      if(this.Listdata.was_donor_prior_infused == "No"){
        if(this.Listdata.test_sample_initial == "" || this.Listdata.test_sample_initial === null){
          this.errr4 = false
          return false,
        this.snackbarText = "Please Fill Test sample inital",
        this.snackbar = true
        }
        if(this.Listdata.test_sample_date == "" || this.Listdata.test_sample_date === null || this.Listdata.test_sample_date == "Invalid date"){
          this.errr5 = false
          return false,
          this.snackbarText = "Please Fill Test sample Date",
          this.snackbar = true
        }else{
          this.errr4= true
          this.errr5= true
        }
      }
      if(this.Listdata.was_donor_prior_infused == "Yes"){
        if(this.Listdata.is_donar_greater_than_12_years == "" || this.Listdata.is_donar_greater_than_12_years == null){
          this.errr6 = false
        return false,
        this.snackbarText = "Please Fill Is the Donor > 12 years old",
        this.snackbar = true
        }else{
          this.errr6 = true
        }
        if(this.Listdata.is_donar_greater_than_12_years == "Yes"){
         if(this.Listdata.has_extravascular_blood_loss_occurred == "" || this.Listdata.has_extravascular_blood_loss_occurred == null){
          this.errr7 = false
          return false,
          this.snackbarText = "Please Fill extravascular blood loss occurred",
          this.snackbar = true
         }else{
           this.errr7 = true
         }
        }
        // if(this.Listdata.is_donar_greater_than_12_years == "No"){
        //   if(this.blood_product_arr == "" || this.colloid_arr == "" || this.crystalloid_arr){
        //     return false,
        //   this.snackbarText = "Please Fill Data",
        //   this.snackbar = true
        //   }
        // }
      }
      if(this.Listdata.final_review_signature == "" || this.Listdata.final_review_signature ==null){
        this.errr8 = false
        return false,
          this.snackbarText = "Please Fill the Signature",
          this.snackbar = true
      }
      if(this.Listdata.final_review_date == "" || this.Listdata.final_review_date === null || this.Listdata.final_review_date == "Invalid date"){
        return false,
          this.snackbarText = "Please Fill the date and time",
          this.snackbar = true
      }
      else{
        this.errr = true
        this.errr1 = true
        this.errr2 = true
        this.errr8 = true
      }
      this.AddHemodilution()
     

    },

    plasma_Volume() {
      this.plasmaVolume = this.Listdata.donor_weight / 0.025
      this.bloodVolume = (this.Listdata.donor_weight / 0.015).toFixed(2)
    },


    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 5) {
        $event.preventDefault()
      }
    },
    onFileChangeReject() {
      this.rejectSampleInitialObj=URL.createObjectURL(this.rejectSampleInitial)
      const reader = new FileReader()
      reader.readAsDataURL(this.rejectSampleInitial)
      reader.onload = e => {
        
        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.rejectSampleInitial = null;
          this.rejectSampleInitialObj = null;
          return;
        }

        this.Listdata.reject_sample_initial = e.target.result
      }
    },
    onFileChange() {
      this.testSampleInitialObj=URL.createObjectURL(this.testSampleInitial)
      const reader = new FileReader()
      reader.readAsDataURL(this.testSampleInitial)
      reader.onload = e => {

        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.testSampleInitial = null;
          this.testSampleInitialObj = null;
          return;
        }

        this.Listdata.test_sample_initial = e.target.result
      }
    },

    onFileclick() {
      this.SignatureObj=URL.createObjectURL(this.Signature)
      const reader = new FileReader()
      reader.readAsDataURL(this.Signature)
      reader.onload = e => {
        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.SignatureObj = null;
          this.Signature = null;
          return;
        }
        this.Listdata.final_review_signature = e.target.result
      }
    },

    async HemodilutionData() {
      const DonorId = this.$route.params.donor_id
      const service = new Hemodilutionservice()
      let response = await service.ListData(DonorId)
      this.Listdata = response
      this.plasmaVolume = this.Listdata.donor_weight / 0.025
      this.bloodVolume = (this.Listdata.donor_weight / 0.015).toFixed(2)

      try {
        this.blood_product_arr = JSON.parse(response.blood_product)
        this.colloid_arr = JSON.parse(response.colloid)
        this.crystalloid_arr = JSON.parse(response.crystalloid)

        // this.wbc_performed_arr = JSON.parse(response.wbc_performed)
        // this.cultures_source_arr = JSON.parse(response.cultures_done)
        // this.temperature_arr = JSON.parse(response.temperature_recorded)
      } catch (error) {

      }

    },

    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[6]
        this.formtype = this.permissionData[7]
        // console.log("heloo",formData)
      if(formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0"){
          this.readOnlyField1 = true
          this.saveBtn = false
        } 
      }else {
      this.totallist = 0
      }
      this.listLoading = false
    },

    restfield(type){
      if(type == 'Yes'){
      this.blood_product_arr = [
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
      ],
      this.colloid_arr = [
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' }
      ],
      this.crystalloid_arr = [
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
      ]
      }
      else if(type = 'No'){
        this.Listdata.has_extravascular_blood_loss_occurred=''
      }
    },

    restbutton(type){
      if(type =='No'){
        this.Listdata.is_donar_greater_than_12_years='', 
      this.blood_product_arr = [
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
      ],
      this.colloid_arr = [
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' }
      ],
      this.crystalloid_arr = [
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
      ]
      }else{
        this.testSampleInitial=null,
        this.testSampleDate = ''
      }
    },

    restbutton2(type){
      if(type == 'Yes'){
        this.testSampleInitial=null,
        this.testSampleDate = ''
      }else{
         this.blood_product_arr = [
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
        { blood_product: '', date_time: '', blood_volume: '' },
      ],
      this.colloid_arr = [
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' },
        { colloid: '', date_time: '', colloid_volume: '' }
      ],
      this.crystalloid_arr = [
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
        { crystalloid: '', date_time: '', crystalloid_volume: '' },
      ]

      }


    },
    

    resetFieldsData(type) {
    
      if (type == 'Temperature') {
        this.temperature_arr = [
          { date_time: '', result: '', units: '' },
          { date_time: '', result: '', units: '' },
          { date_time: '', result: '', units: '' },

        ]
      } else if (type == 'Cultures') {
        this.cultures_source_arr = [
          { cultures_source: '', date_time: '', result: '' },
          { cultures_source: '', date_time: '', result: '' },
          { cultures_source: '', date_time: '', result: '' },

        ]
      } else if (type == 'WBC') {
        this.wbc_performed_arr = [
          { date_time: '', count: '' },
          { date_time: '', count: '' },
          { date_time: '', count: '' },

        ]
      }
    },
    async AddHemodilution() {
      if(this.Listdata.reject_sample_date === null || this.Listdata.reject_sample_date == "Invalid date" || this.Listdata.reject_sample_date == ""){
        this.Listdata.reject_sample_date = null
      }else{
        this.Listdata.reject_sample_date = moment.utc(this.Listdata.reject_sample_date).format('YYYY-MM-DD HH:mm')
      }

      if(this.Listdata.sample_drawn_date_time === null || this.Listdata.sample_drawn_date_time == "Invalid date" || this.Listdata.sample_drawn_date_time == ""){
        this.Listdata.sample_drawn_date_time = null
      }else{
        this.Listdata.sample_drawn_date_time = moment.utc(this.Listdata.sample_drawn_date_time).format('YYYY-MM-DD HH:mm')
      }

      if(this.Listdata.test_sample_date === null || this.Listdata.test_sample_date == "Invalid date" || this.Listdata.test_sample_date == ""){
        this.Listdata.test_sample_date = null
      }else{
        this.Listdata.test_sample_date = moment.utc(this.Listdata.test_sample_date).format('YYYY-MM-DD HH:mm')
      }

      if(this.Listdata.final_review_date === null || this.Listdata.final_review_date == "Invalid date" || this.Listdata.final_review_date == ""){
        this.Listdata.final_review_date = null
      }else{
        this.Listdata.final_review_date = moment.utc(this.Listdata.final_review_date).format('YYYY-MM-DD HH:mm')
      }
      // console.log("reject_sample_date",this.Listdata.reject_sample_date)
    
      const donorid = this.$route.params.donor_id
      const data = {
        id: userId,
        donor_id: donorid,
        // donor_name: this.Listdata.donor_name,
        // donor_number: this.List.donor_number,
        sample_drawn_date_time: this.Listdata.sample_drawn_date_time,
        sample_drawn_by: this.Listdata.sample_drawn_by,
        was_donor_prior_infused: this.Listdata.was_donor_prior_infused,
        is_donar_greater_than_12_years: this.Listdata.is_donar_greater_than_12_years,
        has_extravascular_blood_loss_occurred: this.Listdata.has_extravascular_blood_loss_occurred,
        test_sample_initial: this.Listdata.test_sample_initial,
        test_sample_date: this.Listdata.test_sample_date,
        blood_product: this.blood_product_arr,
        total_blood_products_transfused_in_48_hours: this.Listdata.total_blood_products_transfused_in_48_hours,
        colloid: this.colloid_arr,
        total_colloids_transfused_in_48_hours: this.Listdata.total_colloids_transfused_in_48_hours,
        crystalloid: this.crystalloid_arr,
        total_crystalloid_infused_in_1_hour: this.Listdata.total_crystalloid_infused_in_1_hour,
        plasma_volume: this.plasmaVolume,
        donor_weight: this.Listdata.donor_weight,
        blood_volume: this.bloodVolume,
        reject_sample_initial: this.Listdata.reject_sample_initial,
        reject_sample_date: this.Listdata.reject_sample_date,
        final_review_date: this.Listdata.final_review_date,
        final_review_signature: this.Listdata.final_review_signature,
        // wbc_performed: this.wbc_performed_arr,
        // wbc_performed_yes_or_no: this.Listdata.wbc_performed_yes_or_no,
        // temperature_recorded_no_reason: this.Listdata.temperature_recorded_no_reason,
        // temperature_recorded_yes_or_no: this.Listdata.temperature_recorded_yes_or_no,
        // temperature_recorded: this.temperature_arr,
        // cultures_done: this.cultures_source_arr,
        // cultures_done_yes_or_no: this.Listdata.cultures_done_yes_or_no,
      }
      console.log("data",data)
      try {
        const response = await api.post(`hemodilution/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status) {
          this.snackbarText = response.data.message
          this.HemodilutionData()
          if(this.formtype.view != '0' && this.formtype.view != null){
            this.$router.push({name:'SlitLamp'});
          }
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
